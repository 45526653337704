import React from 'react';
import queryString from 'query-string';
import { reduxForm, FieldArray } from 'redux-form';
import { connect } from 'react-redux';
import { InlineLoading, FormGroupList, Input, Textarea, FormGroup, StaticFormGroup, Button, PageLoading, Select, DateTimePicker } from 'tyb';
import { MultipleSelect } from 'ucode-components';
import { FormGroupField } from 'tyb';
import { NewSelect } from 'ucode-components';
const { loadProductById, createProducts, editProducts } = iceStarkData.store.get('commonAction')?.products;
const { loadcategoriesSelectAll } = iceStarkData.store.get('commonAction')?.categories;
const { loadBrandSelections } = iceStarkData.store.get('commonAction')?.brand;
import { NumberFormat } from 'ucode-utils';
import { UploadFile } from 'ucode-components';
import url from '@/api/urls';
import './productDetail.scss';
import { CreateBrandForm } from './components/index';

const { channelSelection } = iceStarkData.store.get('commonAction')?.channel;



const { fieldAssignSelection } = iceStarkData.store.get('commonAction')?.fieldAssign;
const { getZone } = iceStarkData.store.get('commonAction')?.zone;


import CustomFields from '../../components/customFields/customFields';





const regIntger = /^(\s*)[1-9]\d*(\s*)$/;
const numberFloat = /^(\s*)\d*\.?\d{0,2}(\s*)$/;
const lifeUnitFilter = [
  { text: '天', value: 'DAY' },
  { text: '个月', value: 'MONTH' },
  { text: '年', value: 'YEAR' },
];

const filedTypeComponents = {
  'TEXT': Input,
  'ENUM': NewSelect,
  'NUMBER': Input,
}

const required = (message) => (value) => {
  return (!value || (value + '').trim() === '' ? message : undefined);
}

const requiredName = required('请输入产品名称');
const requiredSalesPrice = required('请输入售价');
const requiredUseFulLife = required('请输入保质期');
const requiredBarcode = required('请输入产品条码');

const selected = (message) => (value) => {
  return value && value !== '' ? undefined : message;
}

const selectedBrand = selected('请选择品牌');
const selectdCategory = selected('请选择一级分类');
const selectdSecondCategory = selected('请选择二级分类');

const maxLength = (max) => (value) => {
  return value && value.length > max ? `至多${max}字符` : undefined
}

const maxLength30 = maxLength(30);
const maxLength50 = maxLength(50);
const maxLength100 = maxLength(100);
const maxLength500 = maxLength(50);
const maxLength1000 = maxLength(1000);
const maxLength14 = maxLength(14);

const minLength = (min) => (value) => {
  return value && value.length < min ? `至少${min}字符` : undefined
}

const minLength11 = minLength(11);

const isNumber = (value) => {
  const pattr = /^[0-9]*$/;
  return pattr.test(value) || !value ? undefined : '请输入整数';
}

const min = (min) => (value) => {
  const myValue = parseInt(value);
  return !isNaN(myValue) && myValue < min ? `大于${min}` : undefined;
}

const min1 = min(1);

const max = (max) => (value) => {
  const myValue = parseInt(value);
  return !isNaN(myValue) && myValue > max ? `小于${max}` : undefined;
}

const max99999 = max(99999);


const validateLotteryNum3 = (value, values, props, name) => {
  let re = /^(\-)?([0-9]*|[0-9]*\.{1}\d{0,5})$/;
  let value2 = '';
  if (value || value == '0') {
    value2 = value + "";
  }
  if (value2 === '' || value2.trim().length == 0) {
    // return '不能为空';
  }
  else if (value2.trim() === '-') {
    return '请输入最多5位小数的数';
  }
  else if (!re.test(value2.trim())) {
    return '请输入最多5位小数的数';
  }
  else if (value2.trim() > 180) {
    return '经度范围-180~180';
  }
  else if (value2.trim() < -180) {
    return '经度范围-180~180';
  }
}
const validateLotteryNum4 = (value, values, props, name) => {

  let re = /^(\-)?([0-9]*|[0-9]*\.{1}\d{0,5})$/;
  let value2 = '';
  if (value || value == '0') {
    value2 = value + "";
  }
  if (value2 === '' || value2.trim().length == 0) {
    // return '不能为空';
  }
  else if (value2.trim() === '-') {
    return '请输入最多5位小数的数';
  }
  else if (!re.test(value2.trim())) {
    return '请输入最多5位小数的数';
  }

  else if (value2.trim() > 90) {
    return '纬度范围-90~90';
  }
  else if (value2.trim() < -90) {
    return '纬度范围-90~90';
  }

}

// const table = ({ fields, zoneList }) => (
//   <div>

//     <div style={{ marginBottom: 20 }} >
//       <div style={{ display: 'inline-block', width: '90px', verticalAlign: 'top', color: '#888' }}> </div>

//       <div className='box' style={{ display: 'inline-block' }}>
//         <div className='box-head' >
//           <div style={{ width: '20%' }}>字段Id</div>
//           <div style={{ width: '30%' }}>字段名称</div>
//           <div style={{ width: '40%' }}>操作</div>
//         </div>
//         {fields.map((item, index) => (

//           <div className='box-body' key={index}>
//             <div style={{ overflow: 'visible', width: '20%' }}>
//               {fields.get(index).id}

//             </div>
//             <div style={{ overflow: 'visible', width: '30%' }}>
//               {fields.get(index).name}
//             </div>
//             <div style={{ overflow: 'visible', width: '40%' }}>

//               {fields.get(index).dictType == 'TXT' &&
//                 <FormGroupField
//                   name={`${item}.rate`}
//                   component={Input}
//                   type="text"
//                   style={{ width: 120 }}
//                   placeholder="请输入"
//                   validate={[maxLength30]}
//                 />
//               }
//               {fields.get(index).dictType == 'NUMBER' &&
//                 <FormGroupField
//                   name={`${item}.rate`}
//                   component={Input}
//                   type="text"
//                   style={{ width: 120 }}
//                   placeholder="请输入数值"
//                   validate={[isNumber, max99999]}

//                 />
//               }
//               {fields.get(index).dictType == 'ENUM' &&
//                 <FormGroupField
//                   name={`${item}.rate`}
//                   component={Select}
//                   list={fields.get(index).dictItems ? fields.get(index).dictItems.map((item2) => { return { value: item2.id, text: item2.name } }) : []}
//                   type="text"
//                   style={{ width: 120 }}
//                   // validate={validateRate}
//                   placeholder="请选择"
//                 />
//               }
//               {fields.get(index).dictType == 'TIME' &&
//                 <FormGroupField
//                   name={`${item}.rate`}
//                   component={DateTimePicker}
//                   style={{ width: 120 }}
//                   // validate={validateRate}
//                   placeholder="请选择"
//                 />
//               }
//               {fields.get(index).dictType == 'LONGITUDE_LATITUDE' &&
//                 <React.Fragment>
//                   {/* <div style={{ display: 'inline-block', verticalAlign: 'top',  }}> */}
//                   <FormGroupField
//                     name={`${item}.rate1`}
//                     component={Input}
//                     type="text"
//                     style={{ width: 120 }}
//                     placeholder="请输入经度"
//                     validate={validateLotteryNum3}
//                   />
//                   {/* </div> */}
//                   {/* <div style={{ display: 'inline-block' , verticalAlign: 'top', }}> */}
//                   <FormGroupField
//                     name={`${item}.rate2`}
//                     component={Input}
//                     type="text"
//                     style={{ width: 120 }}
//                     placeholder="请输入纬度"
//                     validate={validateLotteryNum4}

//                   />
//                   {/* </div> */}
//                 </React.Fragment>
//               }
//               {fields.get(index).dictType == 'PROVINCE' &&
//                 <FormGroupField
//                   name={`${item}.rate`}
//                   component={Select}
//                   list={zoneList.filter(key => key.level == 1)}
//                   style={{ width: 120 }}
//                   // validate={validateRate}
//                   placeholder="请选择"
//                 />
//               }


//               {fields.get(index).dictType == 'CITY' &&
//                 <React.Fragment>
//                   {/* <div style={{ display: 'inline-block', verticalAlign: 'top',  }}> */}
//                   <FormGroupField
//                     name={`${item}.rate1`}
//                     // label="所在地"
//                     placeholder="请选择省"
//                     component={Select}
//                     required={true}
//                     style={{ width: 120 }}
//                     list={zoneList.filter(item => item.level == 1)}
//                   >
//                   </FormGroupField>
//                   {/* </div> */}
//                   {/* <div style={{ display: 'inline-block', verticalAlign: 'top',  }}> */}
//                   <FormGroupField
//                     name={`${item}.rate2`}
//                     placeholder="请选择市"
//                     component={Select}
//                     style={{ width: 120 }}
//                     required={true}
//                     list={zoneList.filter(item => item.level == 2 && item.parentCode == fields.get(index).rate1)}
//                   >
//                   </FormGroupField>
//                   {/* </div> */}
//                 </React.Fragment>
//               }

//               {fields.get(index).dictType == 'DISTRICT' &&
//                 <React.Fragment>

//                   {/* <div style={{ display: 'inline-block', verticalAlign: 'top',  }}> */}
//                   <FormGroupField
//                     name={`${item}.rate1`}
//                     // label="所在地"
//                     placeholder="请选择省"
//                     component={Select}
//                     required={true}
//                     style={{ width: 120 }}
//                     list={zoneList.filter(item => item.level == 1)}
//                   >
//                   </FormGroupField>
//                   {/* </div> */}
//                   {/* <div style={{ display: 'inline-block', verticalAlign: 'top',  }}> */}
//                   <FormGroupField
//                     name={`${item}.rate2`}
//                     placeholder="请选择市"
//                     component={Select}
//                     style={{ width: 120 }}
//                     required={true}
//                     list={zoneList.filter(item => item.level == 2 && item.parentCode == fields.get(index).rate1)}
//                   >
//                   </FormGroupField>
//                   {/* </div> */}
//                   {/* <div style={{ display: 'inline-block' , verticalAlign: 'top', }}> */}
//                   <FormGroupField
//                     name={`${item}.rate3`}
//                     placeholder="请选择区"
//                     component={Select}
//                     required={true}
//                     style={{ width: 120 }}
//                     list={zoneList.filter(item => item.level == 3 && item.parentCode == fields.get(index).rate2)}
//                   >
//                   </FormGroupField>
//                   {/* </div> */}


//                 </React.Fragment>
//               }

//             </div>

//           </div>
//         ))}

//       </div>
//     </div>


//   </div>

// )





@connect(
  state => {
    return {
      form: state.form,
      ...state.products,
      ...state.brand,
      ...state.categories,
      channelSelector: state.channel.channelSelection || [],
      permissionIds: state.users.permissionIds,




      zoneList: state.zone.zoneList,
      zoneListTwoTree: state.zone.zoneListTwoTree,
      zoneListThreeTree: state.zone.zoneListThreeTree,

    };
  },
  {
    loadProductById: loadProductById.REQUEST,
    createProducts: createProducts.REQUEST,
    editProducts: editProducts.REQUEST,
    loadcategoriesSelectAll: loadcategoriesSelectAll.REQUEST,
    loadBrandSelections: loadBrandSelections.REQUEST,
    //渠道
    channelSelection: channelSelection.REQUEST,



    //自定义
    fieldAssignSelection: fieldAssignSelection.REQUEST,
    //zone
    getZone: getZone.REQUEST,

  }
)
class ProductDetailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parseId: '',
      dataId: '',
      uploadImage: false,
      showbrandCreateForm: false,
      systemCustomList: []
    };
  }

  // 价值成本验证
  amountValueValidator = (value, callback) => {
    if (!value || value == 0) {
      return '请输入售价';
    } else if (value && !numberFloat.test(value)) {
      return '请输入数值，支持2位小数';
    } else if (value && Number(value) > 999999.99) {
      return '不大于999999.99';
    } else {
      return '';
    }
  };

  // 自定义字段验证
  requiredSpecValidate = (value, callback, props, formKey) => {
    const stringId = formKey.split('.')[1].slice(7, formKey.split('.')[1].length);
    const curentSystemObj = this.state.systemCustomList.filter(v => v.id == stringId)[0];
    const { filedType = '', name: filedName = '' } = curentSystemObj;

    if (!value || value && value.trim().length < 1) {
      return filedType == 'ENUM' ? `请选择${filedName}` : `请输入${filedName}`;
    } else if (filedType == 'TEXT' && value.trim().length > 50) {
      return '长度不超过50个字符';
    } else if (filedType == 'NUMBER' && (isNaN(value))) {
      return '请输入数字';
    } else {
      return '';
    }
  }

  componentDidMount() {
    const { loadcategoriesSelectAll, loadBrandSelections, categoriesSelectAll = [] } = this.props;
    const parsed = queryString.parse(this.props.location.search) || '';
    loadBrandSelections({ status: "ENABLE" });
    loadcategoriesSelectAll({ status: "ENABLE" });
    this.initForm();
    this.setState({ parseId: parsed.id });
    this.props.channelSelection({ status: 'ENABLE' });
    this.props.getZone();
  }

  componentDidUpdate(prevProps, prevState) {
    const { categoriesSelectAll = [], brandSelecList = [], productDetail = {}, initialize, form = {} } = this.props;
    if (Object.keys(productDetail).length > 0) {
      if (prevProps.categoriesSelectAll.length < 1 && categoriesSelectAll.length > 0) {
        this.initCategories(productDetail);
      }
      if (prevProps.brandSelecList.length < 1 && brandSelecList.length > 0) {
        if (productDetail.brandId && brandSelecList.length > 0) {
          productDetail.brandId = brandSelecList.some(v => v.value == productDetail.brandId) ? productDetail.brandId : '';
          initialize({
            ...form.productDetailForm.values,
            brandId: productDetail.brandId,
          });
        }
      }
    }
  }

  // 编辑表单
  initForm = () => {
    const { loadProductById, brandSelecList = [], categoriesSelectAll = [] } = this.props;
    const parsed = queryString.parse(this.props.location.search) || '';
    if (parsed.id) {
      loadProductById({
        id: parsed.id,
        callback: (data) => {
          const { initialize } = this.props;
          // 设置分类（一级，二级，自定义字段）
          if (categoriesSelectAll.length > 0) this.initCategories(data);

          // 设置图片
          if (data.images) {
            data.image = JSON.parse(data.images)[0].filePath;
          }

          // 设置品牌
          if (data.brandId && brandSelecList.length > 0) {
            data.brandId = brandSelecList.some(v => v.value == data.brandId) ? data.brandId : '';
          }

          Object.keys(data).map(v => {
            if (data[v] == null) delete data[v];
          })

          if (data.data) {
            data.data = JSON.parse(data.data);
          }
          else {
            data.data = []
          }

          initialize({ ...data, data: [], channelIds: JSON.parse(data.channelIds || '[]') });


          this.props.fieldAssignSelection({
            // data: { dictDataType: 1 },
            data: { dictDataType: 'PRODUCT' },
            callback: (res) => {
              if (data.data) {
                let b = res.map((item) => { return { ...item, rate: item.dictType == 'TIME' ? new Date() : '', ...(data.data.filter(key => key.id == item.id)[0] || {}) } })
                this.props.change('data', b)
              }
              else {
                let b = res.map((item) => { return { ...item, rate: item.dictType == 'TIME' ? new Date() : '' } })
                this.props.change('data', b)
              }

            }
          })

        }
      });
    }
    else {
      this.props.fieldAssignSelection({
        // data: { dictDataType: 1 },
        data: { dictDataType: 'PRODUCT' },
        callback: (res) => {

          let b = res.map((item) => { return { ...item, rate: item.dictType == 'TIME' ? new Date() : '' } })
          this.props.change('data', b)

        }
      })

    }
  }

  // 设置分类（一级，二级，自定义字段）
  initCategories = (data) => {
    const { categoriesSelectAll = [], initialize, form = {} } = this.props;
    if (data.category) {
      if (categoriesSelectAll.filter(item => item.id == data.category).length > 0) {
        this.categoryChange(data.category, 2, 'secondCategoryTree');
        if (data.secondCategory) {
          if (categoriesSelectAll.filter(value => value.parentId == data.category && value.id == data.secondCategory).length < 1) {
            data.secondCategory = "";
            if (data.systemCustom) delete data.systemCustom;
          }
        }
      } else {
        data.category = "";
        data.secondCategory = "";
        if (data.systemCustom) delete data.systemCustom;
      }
    }

    if (data.systemCustom && data.secondCategory) {
      let systemCustom = JSON.parse(String(data.systemCustom));
      if (!systemCustom.length) {
        systemCustom = [systemCustom];
      }
      data.systemCustomList = {};
      systemCustom.map(val => {
        Object.assign(data.systemCustomList, {
          [`filedId${val.filedId}`]: val.value || val.content
        });
      })
      this.systemCustomRender(data.secondCategory);
      initialize({
        ...form.productDetailForm.values,
        ...data,
        data: form.productDetailForm.values?.data
      })
    }
  }

  // 提交表单
  handleSubmit = values => {
    const data = JSON.parse(JSON.stringify(values));
    data.images = JSON.stringify([{ index: 0, filePath: data.image }]);
    if (data.systemCustomList) {
      const systemCustomList = [];
      for (let i in data.systemCustomList) {
        systemCustomList.push({ filedId: i.slice(7, i.length), value: data.systemCustomList[i] });
      }
      data.systemCustom = JSON.stringify(systemCustomList);
    }
    data.channelIds = JSON.stringify(data.channelIds) || '[]'
    data.data = data.data ? data.data.map(item => { return { code: item.code, id: item.id, value: item.value, value1: item.value1, value2: item.value2, value3: item.value3 } }) : []
    data.data = JSON.stringify(data.data) || '[]'

    this.trims(data);
    this.props[`${data.id ? 'editProducts' : 'createProducts'}`]({ data, callback: this.handleClose });
  };

  handleClose = () => {
    this.props.history.goBack();
  };

  // 统一去前后空格
  trims = obj => {
    for (let key in obj) {
      if (obj[key] == null) {
      }
      else if (obj[key].constructor === String) {
        obj[key] = obj[key].trim();
      } else if (obj[key].constructor === Object) {
        this.trims(obj[key]);
      }
    }
  }

  // 渲染二级分类
  categoryChange = (value, level, tree) => {
    this.setState({ systemCustomList: [], [tree]: this.props.categoriesSelectAll.filter(v => v.parentId == value && v.level == level) });
    const { form: { productDetailForm = {} } } = this.props;
    this.props.initialize({
      ...productDetailForm.values,
      secondCategory: '',
      systemCustomList: {}
    });
  };

  // 遍历自定义列表
  systemCustomRender = value => {
    let systemCustomList = [];
    if (this.props.categoriesSelectAll.length > 0) systemCustomList = this.props.categoriesSelectAll.filter(v => v.id == value && v.level == 2 && v.fileds)[0].fileds;
    systemCustomList.map(v => {
      if (v.enums && v.filedType == 'ENUM') {
        v.enumsList = JSON.parse(v.enums);
        v.enumsList.map(val => {
          val.text = val.name;
          v.value = val.value;
        });
      } else {
        v.enumsList = [];
      }
    });
    this.setState({ systemCustomList });
    const { form: { productDetailForm = {} } } = this.props;
    this.props.initialize({
      ...productDetailForm.values,
      systemCustomList: {}
    })
  };

  render() {
    const { parseId, secondCategoryTree, systemCustomList = [], showbrandCreateForm, dataId = '' } = this.state;
    const { categoriesSelectAll, brandSelecList, handleSubmit, form = {}, loadBrandSelections, loadProductByIdLoad, channelSelector } = this.props;
    const { productDetailForm = {} } = form;
    const { values = {} } = productDetailForm;
    const { brandId = '', image = '', systemCustom = {} } = values;
    if (loadProductByIdLoad) {
      return <PageLoading />;
    } else {
      return (
        <div className="productdetail-container">
          <div className="">
            <h4 style={{ fontSize: '14px', fontWeight: 'bold' }}>基本信息</h4>
            <FormGroupList>
              <FormGroupField
                className="button-after"
                name={'mid'}
                label="物料编码"
                control={Input}
                // required
                validate={[maxLength30]}
                component={FormGroup}
              />
              {/* 产品名称 */}
              <FormGroupField
                className="button-after"
                name={'name'}
                label="产品名称"
                control={Input}
                required
                validate={[requiredName, maxLength50]}
                component={FormGroup}
                text="长度不超过50个字符"
              />
            </FormGroupList>
            <FormGroupList>
              {/* 产品名称 */}
              <FormGroupField
                className="button-after"
                name={'code'}
                label="产品编码"
                control={Input}
                disabled={values.id}
                // required
                validate={[maxLength500]}
                component={FormGroup}
                text="保存后不可修改，最长50字符"
              />
            </FormGroupList>
            {/* 品牌 NewSelect */}
            <FormGroupList>
              <FormGroupField
                className="button-after"
                name={'brandId'}
                label="品牌"
                required
                list={brandSelecList}
                validate={[selectedBrand]}
                component={NewSelect}
                placeholder="选择品牌"
                style={{ width: 330 }}
                type="text"
              >
                <a href="javascript:;" className="button-after-text2" onClick={() => { this.setState({ showbrandCreateForm: true, dataId: '' }); }} >新增 </a>
                {brandId && <a href="javascript:;" className="button-after-text2" onClick={() => { this.setState({ showbrandCreateForm: true, dataId: brandId }); }} > 编辑 </a>}
              </FormGroupField>

            </FormGroupList>
            {/* 分类 */}
            <FormGroupList>
              <FormGroupField
                className="half-row category-input "
                name={'category'}
                label="分类"
                control={NewSelect}
                style={{ width: 160 }}
                list={categoriesSelectAll.filter(v => v.level == 1)}
                component={FormGroup}
                validate={[selectdCategory]}
                required
                onChange={value => {
                  this.categoryChange(value, 2, 'secondCategoryTree');
                }}
                placeholder="选择一级分类"
              />
              <FormGroupField
                className="half-row label10"
                name={'secondCategory'}
                control={NewSelect}
                style={{ width: 160 }}
                list={secondCategoryTree}
                component={FormGroup}
                validate={[selectdSecondCategory]}
                onChange={value => {
                  this.systemCustomRender(value);
                }}
                placeholder="选择二级分类"
              />
            </FormGroupList>

            <FormGroupList>
              {/* 规格 */}
              {/* TODO: 字段 */}
              {
                systemCustomList.length > 0 && (
                  systemCustomList.map((elem, index) => {
                    return <React.Fragment key={`${elem.name}${index}`}>
                      <FormGroupField
                        className="button-after"
                        name={`systemCustomList.filedId${elem.id}`}
                        label={`${elem.name}`.length > 11 ? `${elem.name}`.slice(0, 12).concat('...') : `${elem.name}`}
                        // label={elem.name}
                        control={filedTypeComponents[elem.filedType]}
                        list={elem.enumsList}
                        placeholder={elem.filedType == 'ENUM' ? `请选择${elem.name}` : `请输入${elem.name}`}
                        required
                        validate={this.requiredSpecValidate}
                        component={FormGroup}
                        style={{ width: 330 }}
                      />
                    </React.Fragment>
                  })
                )
              }

              {/* 售价 */}
              <FormGroupField
                className="amount-input"
                name={'salesPrice'}
                label="售价"
                required
                control={Input}
                addonAfter={'元'}
                validate={this.amountValueValidator}
                component={FormGroup}
                format={value => NumberFormat(value)}
                text='保留小数点后两位，最小0.01，最大999999.99'
              />
            </FormGroupList>

            <FormGroupList>
              {/* 产品名称 */}
              <FormGroupField
                name={'barcode'}
                label="产品条码"
                required
                control={Input}
                validate={[ requiredBarcode,isNumber, minLength11, maxLength14]}
                component={FormGroup}
                // format={value => NumberFormat(value)}
                text="填写正整数, 11-14位数字"
              />
            </FormGroupList>

            <FormGroupList>
              <FormGroupField
                className="button-after"
                name={'barcodeBrand'}
                label="物编登记信息"
                control={Input}
                required
                validate={[ requiredName,maxLength30]}
                component={FormGroup}
                text="填写编码中心登记的品牌信息，最多30字符"
              />
            </FormGroupList>
            <FormGroupList>
              <FormGroupField
                className="button-after"
                name={'channelIds'}
                label="销售渠道"
                list={channelSelector.map((item) => { return { value: item.id, text: item.name } })}
                control={MultipleSelect}
                // required
                // validate={[ maxLength30]}
                component={FormGroup}
              />
            </FormGroupList>

            {/* 保质期 */}
            <FormGroupList>

              <FormGroupField
                className="half-row usefulLife-input"
                name={'usefulLife'}
                label="保质期"
                required
                control={Input}
                validate={[ requiredUseFulLife,isNumber, min1, max99999]}
                component={FormGroup}
                format={value => NumberFormat(value)}
                text="填写正整数, 最大99999"
              />
              <FormGroupField
                className="half-row label10"
                name={'lifeUnit'}
                control={NewSelect}
                style={{ width: 160 }}
                list={lifeUnitFilter}
                component={FormGroup}
                format={value => NumberFormat(value)}
              />
            </FormGroupList>

            <FormGroupList>
              {/* 产品图片 */}
              <StaticFormGroup
                name={'image'}
                label="产品图片"
                component={FormGroup}
              >
                <div className="prize-image">
                  {this.state.uploadImage && <InlineLoading />}
                  {image && <img src={image} alt="产品图片" />}
                  {!image && <span>还没有上传图片，可不上传</span>}
                </div>

                <UploadFile
                  className="prize-image-upload"
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                  action={url.data.upload.upload('PUBLIC')}
                  size={5}
                  onStartCallback={() => {
                    this.setState({ uploadImage: true });
                  }}
                  onSuccessCallback={(file) => {
                    const { form = { productDetailForm: {} }, initialize } = this.props;
                    const { productDetailForm = { values: {} } } = form;
                    initialize({
                      ...productDetailForm.values,
                      image: file.url
                    });
                    this.setState({ uploadImage: false });
                  }}
                >
                  <Button className="weak">选择图片</Button>
                </UploadFile>
                <span className="extra-text" style={{ display: 'block' }}>
                  {' '}请上传jpg、png、gif格式的图片，大小在5M以内{' '}
                </span>
              </StaticFormGroup>

              {/* 产品介绍 */}
              <FormGroupField
                name={'info'}
                label="产品介绍"
                control={Textarea}
                validate={[maxLength1000]}
                component={FormGroup}
                text="长度不超过1000个字符"
              />
            </FormGroupList>

            {/* {fieldAssignSelect.map((item, index) => {
              return <div key={index}>
                <div>{item.name}</div>
                {item.dictType == 'ENUM' && <div>
                  <FormGroupField
                    className="button-after"
                    name={'channelIds2222'}
                    list={item.dictItems ? item.dictItems.map((item2) => { return { value: item2.id, text: item2.name } }) : []}
                    component={MultipleSelect}
                  />
                </div>}
              </div>
            })} */}
            {/* <h4 style={{ fontSize: '14px', fontWeight: 'bold' }}>自定义字段</h4> */}
            {/* <FieldArray name="data" component={table} zoneList={this.props.zoneList} /> */}

            <CustomFields name='data' handleChange={e => {
              const { index, url } = e
              let arr = values?.data || []
              arr = arr.map((it, i) => {
                if (i === +index) {
                  return {
                    ...it,
                    value: url || ''
                  }
                } else {
                  return it
                }
              })

              this.props.change('data', arr)
            }}></CustomFields>







            <div className="button-box create-prize-button-box">
              {(this.props.permissionIds.includes('data.basicData.product.add') || this.props.permissionIds.includes('data.basicData.product.edit')) && <Button className="" style={{ marginRight: '10px' }} onClick={handleSubmit(this.handleSubmit)}> {parseId ? '确定' : '创建'} </Button>}
              <Button className="weak" onClick={this.handleClose}>取消</Button>
            </div>
          </div>
          {showbrandCreateForm && <CreateBrandForm
            dataId={dataId}
            showbrandCreateForm={showbrandCreateForm}
            handleCancel={() => {
              loadBrandSelections({ status: "ENABLE" });
              this.setState({ showbrandCreateForm: false });
            }}
          />}
        </div>
      );
    }
  }
}
const productDetailForm = reduxForm({
  form: 'productDetailForm',
  initialValues: {
    lifeUnit: 'DAY'
  },
})(ProductDetailForm);
export default productDetailForm;
